@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bakbak-one-regular {
  font-family: "Bakbak One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-regular {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-bold {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.barlow-regular-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.bg-darkblue{
  background-color: #1b1464;
}

.bg-lightgrey{
  background-color: #e5e4dc;
}

.bg-lightblue{
  background-color: #00aeef;
}

.bg-blue{
  background-color: #0072bc;
}

.bg-blueband{
  background-color: #495881;
}

.text-blue{
  color: #2e3192;
}

.text-darkblue{
  color: #4d5075;
}

.border-darkblue{
  border-color: #4d5075;
}

/* NEWS LANDING PAGINATION */
.pagination>li {
  display: inline;
}

.news-pagination>ul>li.disabled>a, .news-pagination>ul>li.disabled>a:focus, .news-pagination>ul>li.disabled>a:hover, .news-pagination>ul>li.disabled>span, .news-pagination>ul>li.disabled>span:focus, .news-pagination>ul>li.disabled>span:hover {
  color:#bcbebe!important;
  cursor: not-allowed;
}

/* NEWS INNER IMAGE CAPTION */
.news-inner-style figure{
  width: 100%!important;
}

.news-inner-style figure figcaption{
  font-size: 14px;
}

/* RESOURCES BG COLOR */
/* blue */
#resources-categories section{
  background-color: #444e69;
  color: #fff;
}

/* beige */
#resources-categories section:nth-child(3n-1){
  background-color: #e4e4dc;
  color: #2e3192;
}

/* green */
#resources-categories section:nth-child(3n){
  background-color: #7bcbc8;
  color: #2e3192;
}

#resources-categories section:nth-child(3n) div>a, #resources-categories section:nth-child(3n-1) div>a{
  width: 100%;
  border-color: #2e3192;
}

.programs-inner h1{
  font-family: "Bakbak One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}

.programs-inner p, .programs-inner ol li, .programs-inner ul li{
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 1.25rem;
}

.programs-inner ol li{
  list-style-type: decimal;
  margin-left: 15px;
}

.programs-inner ul li{
  list-style-type: disc;
  margin-left: 15px;
}

iframe#wherewework-map{
  height: 350px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  iframe#wherewework-map{
    height: 450px;
  }
}

@media all and (min-width: 1200px) {
  .programs-inner img{
    margin: 0!important;
  }

  iframe#wherewework-map{
    height: 500px;
  }
}

#about-implementers{
  background: url("/public/images/whoarewe/mmfmp-whoarewe-implementers.jpg") no-repeat top center;
  background-size: cover;
}

table#wherewework-table td{
  border: 2px solid white;  
}

table#wherewework-table thead td{
  font-family: "Bakbak One", sans-serif;
  text-transform: uppercase;
  text-align: center;
  background-color: #179ed9;
  color: #fff;
  font-size: 18px;
  padding: 20px;
}

table#wherewework-table tbody td{
  font-family: "Barlow", sans-serif;
  text-align: center;
  background-color: #2e3192;
  color: #fff;
  font-size: 15px;
  padding: 20px;
}

.table-responsive{
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
  overflow-y: hidden;
}